import React from "react";
import { Box, Typography } from "@mui/material";

import Item from "../layouts/components/Item";
import CardResources from "../components/CardResources";

import LewioLaptopImage from "../images/lewiolaptop.jpg";
import ProcessoImage from "../images/juridico/processos.jpg";
import AudienciaImage from "../images/juridico/audiencia.jpg";
import PrazoImage from "../images/juridico/prazos.jpg";
import FinanceiroImage from "../images/juridico/financeiro.jpg";
import PublicacoesImage from "../images/juridico/publicacoes.jpg";
import GedImage from "../images/juridico/ged.jpg";
import MalaImage from "../images/juridico/maladireta.jpg";

export default function abiljur() {
  return (
    <div>
      <div
        style={{
          height: "25rem",
          marginTop: "-20px",
          width: "100%",
          backgroundImage: `url(${LewioLaptopImage})`,
          backgroundRepeat: "no-repeat"
        }}
      >
        <Box ml={15} pt={13} sx={{ color: "#FFF" }} >
          <Typography fontSize="2.0rem" fontStyle="100">
            SOFTWARE
          </Typography>
          <Typography fontSize="2.0rem" fontStyle="100">
            JURÍDICO
          </Typography>
          <Typography fontSize="1.4rem" fontStyle="100">
            Seu escritório de advocacia
          </Typography>
          <Typography fontSize="1.4rem" fontStyle="100">
            muito mais produtivo
          </Typography>
        </Box>
      </div>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-around",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Item>
          <CardResources
            image={ProcessoImage}
            imageAlt="Gerenciamento de Processos"
            title="Gerenciamento de Processos"
            subtitle="Organização e gerenciamento"
            text="Organiza os processos em pastas numeradas, seus andamentos e
                  todas as informações referente ao processo em uma tela única,
                  permitido acesso rápido e fácil às informações do processo.
                  Gerencia os controles específicos para controle de reclamantes
                  e reclamadas mediante as informações próprias aos clientes."
          />
        </Item>
        <Item>
          <CardResources
            image={AudienciaImage}
            imageAlt="Gerenciamento de Audiência"
            title="Gerenciamento de Audiência"
            subtitle="Organização em pautas"
            text="Organiza a agenda da audiência em pautas, permitindo envio aos clientes de cartas personalizadas, e-mail com as informações da audiência e SMS com o lembrete dias antes da audiência."
          />
        </Item>

        <Item>
          <CardResources
            image={PrazoImage}
            imageAlt="Controle de Prazos e Ordens de Serviço"
            title="Controle de Prazos e Ordens de Serviço"
            subtitle="Gerador de ordens de serviços para os advogados"
            text="Gerencia os prazos dos processos gerando ordens de serviço para os advogados responsáveis ou para a equipe de advogados. Gera atividades automaticamente para os advogados e participantes das atividades do escritório de prazos de sentença e verificação de processos sem andamentos."
          />
        </Item>

        <Item>
          <CardResources
            image={FinanceiroImage}
            imageAlt="Gerenciamento Financeiro"
            title="Gerenciamento Financeiro"
            subtitle="Controle de entradas e saidas"
            text="Gerencia todos os valores lançados em cada processo, sejam acordos, alvarás, honorários e despesas emitindo cheques e recibos ao cliente. Controla o Contas a Pagar e a Receber, imprime relatórios de previsão de recebimento, pagamentos e fluxo de caixa."
          />
        </Item>

        <Item>
          <CardResources
            image={PublicacoesImage}
            imageAlt="Intimações, Publicações e ATAS das Audiências."
            title="Intimações e Publicações"
            subtitle="Leitura e direcionamento das ações"
            text="Permite que duas intimações sejam importadas diretamente ao processo da AASP por arquivo ou API permitindo os usuários executar as ações necessárias ao processo durante a leitura da intimação ou da Ata"
          />
        </Item>

        <Item>
          <CardResources
            image={GedImage}
            imageAlt="Gerenciamento Eletrônico de Documentos"
            title="Gerenciamento Eletrônico de Documentos"
            subtitle="Armazenamento de Documentos do processo"
            text="Armazena todos os documentos relacionados ao processo nos formatos de documentos mais comerciais com fácil acesso e inclusão dos dados no sistema.

            O sistema permite definir modelos de documentos em Word como petições, inicial, notificações, etc. que com apenas um click os dados do processo são transportados para o documento através de macros de substituição.
            "
          />
        </Item>

        <Item>
          <CardResources
            image={MalaImage}
            imageAlt="Mala Direta"
            title="Mala Direta"
            subtitle="Relacionamento com o Cliente"
            text="Armazena todos os documentos relacionados ao processo nos formatos de documentos mais comerciais com fácil acesso e inclusão dos dados no sistema.
            O sistema permite definir modelos de documentos em Word como petições, inicial, notificações, etc. que com apenas um click os dados do processo são transportados para o documento através de macros de substituição.
            "
          />
        </Item>
      </Box>
    </div>
  );
}
