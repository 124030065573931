import React from "react";
import { Card, CardContent, Typography, CardMedia, Box } from "@mui/material";
import { isBrowser } from "react-device-detect";

interface IProps {
  image: string;
  imageAlt: string;
  title: string;
  text: string;
  subtitle: string;
}

export default function CardImageLeft(props: IProps): JSX.Element {
  return (
    <Card
      sx={{
        display: "flex",
        height: 330,
        maxHeight: { xs: 433, md: 367 },
        maxWidth: { xs: 540, md: 650 },
      }}
    >
      {isBrowser && (
        <CardMedia
          component="img"
          sx={{
            height: 180,
            objectFit: "contain",
            alignItems: "flex-start",
          }}
          image={props.image}
          alt={props.imageAlt}
        />
      )}
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography variant="h5" component="div">
            {props.title}
          </Typography>
          <Typography
            gutterBottom
            variant="body1"
            component="div"
            color="text.secondary"
          >
            {props.subtitle}
          </Typography>
          <Typography variant="body2" color="Black">
            {props.text}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
}
