import React, { useState, MouseEvent } from "react";
import {
  AppBar,
  Container,
  Toolbar,
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../images/lewio.png";
import { useNavigate } from "react-router-dom";

const pages = [
  { text: "Solução para Advocacia", route: "abiljur" },
  { text: "Folha de Pagamento", route: "motiro" },
  { text: "Downloads", route: "downloads" },
];

interface IProps {
  handleOpen: () => void;
}

const Header: React.FC<IProps> = (props: IProps) => {
  const [anchorElNav, setAnchorElNav] = useState<Element | null>(null);
  const [menuActive, setMenuActice] = useState<number | null>(null);

  let navigate = useNavigate();

  const handleOpenNavMenu = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorElNav(e.currentTarget);
  };

  const handleCloseNavMenu = (index: number) => {
    setAnchorElNav(null);
    setMenuActice(index);
    navigate(`/${pages[index].route}`);
  };

  const handleHome = () => {
    setMenuActice(null);
    navigate("/");
  };

  return (
    <AppBar position="fixed" color="default">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component="img"
            sx={{
              height: 50,
              mr: 2,
              display: { xs: "none", md: "flex", cursor: "pointer" },
            }}
            alt="Lewio"
            src={Logo}
            onClick={handleHome}
          />
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page, index) => (
                <MenuItem
                  key={page.text}
                  onClick={() => handleCloseNavMenu(index)}
                >
                  <Typography textAlign="center">{page.text}</Typography>
                </MenuItem>
              ))}
              <MenuItem onClick={props.handleOpen}>
                <Typography textAlign="center">Fale Conosco</Typography>
              </MenuItem>
            </Menu>
          </Box>

          <Box
            component="img"
            justifyContent="center"
            sx={{
              height: 50,
              display: { xs: "flex", md: "none", cursor: "pointer" },
            }}
            alt="Lewio"
            src={Logo}
            onClick={handleHome}
          />

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page, index) => (
              <Button
                color={index === menuActive ? "success" : "primary"}
                key={index}
                onClick={() => handleCloseNavMenu(index)}
                sx={{ my: 2, display: "block" }}
              >
                {page.text}
              </Button>
            ))}
            <Button
              color="primary"
              onClick={props.handleOpen}
              sx={{ my: 2, display: "block" }}
            >
              Fale Conosco
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
