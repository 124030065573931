import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  CardMedia,
  Button,
  Container,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AbilJurImage from "../images/abiljur.png";
import MotiroImage from "../images/motiro.png";
import FaleConosco from "./FaleConosco";
import { urlMotiro } from "../Constants";

const Main: React.FC = () => {
  let navigate = useNavigate();

  const handleMotiroDetalhes = () => {
    navigate(`/motiro`);
  };

  const handleAbiljurDetalhes = () => {
    navigate(`/abiljur`);
  };

  const handleAccessMotiro = () => {
    window.open(urlMotiro, "_blank");
  };

  const [open, setOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container>
      <Grid
        container
        rowSpacing={1}
        mt={8}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item xs={12} lg={6} md={6}>
          <Card sx={{ display: "flex" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent>
                <Typography component="h2" variant="h5" minWidth={130}>
                  Plataforma Colaborativa de Folha de Pagamento
                </Typography>
              </CardContent>
              <Button
                sx={{ m: "6px" }}
                size="medium"
                variant="outlined"
                onClick={handleMotiroDetalhes}
              >
                MAIS DETALHES
              </Button>
              <Button
                sx={{ m: "6px" }}
                size="medium"
                variant="contained"
                onClick={handleAccessMotiro}
              >
                EXPERIMENTE
              </Button>
            </Box>
            <CardMedia
              component="img"
              sx={{
                height: 180,
                width: 180,
                padding: "8px",
                objectFit: "cover",
              }}
              image={MotiroImage}
              alt="Plataforma Colaborativa de Folha de Pagamento"
            />
          </Card>
        </Grid>
        <Grid item xs={12} lg={6} md={6}>
          <Card sx={{ display: "flex" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography component="h2" variant="h5" minWidth={130}>
                  Solução Escritório de Advocacia
                </Typography>
              </CardContent>
              <Button
                sx={{ m: "6px" }}
                size="medium"
                variant="outlined"
                onClick={handleAbiljurDetalhes}
              >
                MAIS DETALHES
              </Button>
              <Button
                sx={{ m: "6px" }}
                size="medium"
                variant="contained"
                onClick={handleClickOpen}
              >
                ENTRE EM CONTATO
              </Button>
            </Box>
            <CardMedia
              component="img"
              sx={{
                height: 180,
                width: 180,
                padding: "8px",
                objectFit: "cover",
              }}
              image={AbilJurImage}
              alt="Sistema para Gestão de Escritórios de Advocacia"
            />
          </Card>
        </Grid>
      </Grid>
      <Typography variant="h4" component="h5" color="primary.dark" mt={5}>
        A empresa
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: 400 }} mt={2}>
        A Lewio é uma empresa que está no mercado há mais de 20 anos provendo
        soluções para folha de pagamento, escritório de advocacia e
        desenvolvendo soluções personalizadas para diversas áreas do mercado.
      </Typography>
      <Typography variant="h5" component="h6" color="primary.dark" mt={5}>
        Missão
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: 400 }} mt={2}>
        Fornecer soluções inovadoras e prestar um atendimento eficiente,
        melhorando a qualidade dos serviços prestados pelos nossos cliente.
      </Typography>
      <Typography variant="h5" component="h6" color="primary.dark" mt={5}>
        Visão
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: 400 }} mt={2}>
        Ser reconhecida como uma empresa inovadora criando soluções
        colaborativas.
      </Typography>
      <FaleConosco handleClose={handleClose} open={open} />
    </Container>
  );
};

export default Main;
