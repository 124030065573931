import React from "react";
import { Snackbar } from "@mui/material";

import MuiAlert, { AlertProps, AlertColor } from "@mui/material/Alert";

interface IProps {
  message: string;
  severity: AlertColor;
  onClose: () => void;
}

const AlertMessage: React.FC<IProps> = (props: IProps) => {
  const { message, severity } = props;

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    props.onClose();
  };

  return (
    <Snackbar
      open={true}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;
