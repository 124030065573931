import React from "react";
import { Box, Typography, Button } from "@mui/material";

import Item from "../layouts/components/Item";
import CardImageLeft from "../components/CardImageLeft";

import BannerImage from "../images/folha/banner.png";
import ContatadosImage from "../images/folha/contratados.gif";
import FichaFinanceiraImage from "../images/folha/fichafinanceira.gif";
import ImportacoesImage from "../images/folha/importacoes.gif";
import DashboardImage from "../images/folha/dashboard.gif";
import RotinasCalculoImage from "../images/folha/rotinascalculo.gif";
import TabelasColunasImage from "../images/folha/tabelascolunas.gif";
import RegrasColunasImage from "../images/folha/regrascolunas.gif";
import LinguagemSSSImage from "../images/folha/linguagemsss.gif";
import { urlMotiro } from "../Constants";

export default function motiro() {
  const handleAccessMotiro = () => {
    window.open(urlMotiro, "_blank");
  };

  return (
    <div>
      <div
        style={{
          height: "25rem",
          marginTop: "-20px",
          backgroundImage: `url(${BannerImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}
      >
        <Box
          sx={{
            height: "25rem",
            marginTop: "-20px",
            color: "#FFF",
            backgroundColor: "#000",
            opacity: 0.7,
          }}
        >
          <Box p={11}>
            <Typography sx={{ fontSize: "1.9rem", fontWeight: 100 }}>
              PLATAFORMA
            </Typography>
            <Typography sx={{ fontSize: "1.9rem", fontWeight: 100 }}>
              MOTIRÕ
            </Typography>
            <Typography sx={{ fontSize: "1.2rem", fontWeight: 100 }}>
              Sua folha de pagamento colaborativa
            </Typography>
            <Typography sx={{ fontSize: "1.2rem", fontWeight: 600 }}>
              Seu Departamento Pessoal mais eficiente
            </Typography>
            <Button
              sx={{ m: "8px" }}
              size="large"
              variant="contained"
              onClick={handleAccessMotiro}
            >
              Experimente Grátis
            </Button>
          </Box>
        </Box>
      </div>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-around",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Item>
          <CardImageLeft
            image={ContatadosImage}
            imageAlt="Contratados"
            title="Contratados"
            subtitle="Cadastro de contratados"
            text="Permite o cadastramento dos contratados da empresa de qualquer categoria. Os campos a serem preenchidos são dinâmicos e são exibidos ou não de acordo com o tipo de informação requerida pelo eSocial. Por exemplo, se o contrato é pelo regime CLT, exibe campos como Regime da jornada, Sindicato, FGTS, etc. Se é Estatutário, informações como Provimento, Posse, etc. Qualquer tabela do sistema permite definir regras para mudar o comportamento dos campos."
          />
        </Item>

        <Item>
          <CardImageLeft
            image={FichaFinanceiraImage}
            imageAlt="Ficha financeira"
            title="Ficha Financeira"
            subtitle="Resultado dos cálculos e memória de cálculo"
            text="Os cálculos são em tempo real (o sistema faz cálculos dinâmicos, sem necessidade de executá-los) e a ficha financeira mostra o resultado separados por tipo de folha.  É possível definir cores diferentes para facilitar a consulta. Essas cores são possíveis em qualquer tabela do sistema.  Ao clicar em um evento, é possível consultar toda a memória de cálculo, o que permite conferir instantaneamente os valores calculados."
          />
        </Item>

        <Item>
          <CardImageLeft
            image={ImportacoesImage}
            imageAlt="Importação"
            title="Importação"
            subtitle="Importação de dados"
            text="Todas as tabelas podem ser importadas a partir de arquivos Excel, cujo conteúdo pode ser baixado do sistema com a estrutura e definições de regras da tabela. Basta preencher o arquivo Excel, arrastar e soltar na área de importação e, em alguns segundos, o arquivo é validado e, se ok, os dados importados."
          />
        </Item>

        <Item>
          <CardImageLeft
            image={DashboardImage}
            imageAlt="Dashboards"
            title="Dashboards"
            subtitle="Métricas e indicadores"
            text="Permite consultar diversos gráficos instantâneos para melhor compreensão dos indicadores da folha. É possível criar os gráficos, que ficam disponíveis nessa área."
          />
        </Item>

        <Item>
          <CardImageLeft
            image={RotinasCalculoImage}
            imageAlt="Rotinas de cálculo"
            title="Rotinas de cálculo"
            subtitle="Manutenção das regras de cálculo"
            text="É possível atualizar as regras de qualquer evento da folha. Cada evento possui a sua própria regra de cálculo. Além das rotinas básicas de folha, outras rotinas podem ser criadas. A partir de eventos criados e suas respectivas fórmulas, algoritmos analisam e definem a melhor maneira de chegar no resultado da folha e geram uma nova versão dos cálculos que serão executados dinamicamente."
          />
        </Item>

        <Item>
          <CardImageLeft
            image={TabelasColunasImage}
            imageAlt="Tabelas e colunas"
            title="Tabelas e Colunas"
            subtitle="Configuração de tabelas e colunas"
            text="Novas tabelas e novas colunas podem ser criadas e vários tipos de regras podem ser definidas para elas: regras de coluna (validação, conteúdo automáticos, fórmulas etc.) e regras de tabela (validações e gatilhos pré e pós inclusão, alteração ou exclusão)."
          />
        </Item>

        <Item>
          <CardImageLeft
            image={RegrasColunasImage}
            imageAlt="Regras de coluna"
            title="Regras de Coluna"
            subtitle="Manutenção das regras de coluna"
            text="Cada coluna pode ter vários tipos de regra e cada regra pode ter diferentes versões que atendam diferentes usuários. O usuário pode criar uma nova versão de uma regra, desconectar a existente e conectar a sua. Esse esquema de versões também é válido para fórmulas de eventos, gatilhos e validações de tabela etc."
          />
        </Item>

        <Item>
          <CardImageLeft
            image={LinguagemSSSImage}
            imageAlt="Linguagem SSS"
            title="Linguagem SSS"
            subtitle="Linguagem SSS Linguagem Script Step by Step"
            text="Essa linguagem de alto nível permite escrever as regras e fórmulas utilizadas na plataforma através de uma abordagem passo-a-passo que garante uma melhor compreensão da lógica e permite que o sistema automaticamente elimine códigos que já não estão sendo utilizados."
          />
        </Item>
      </Box>
    </div>
  );
}
