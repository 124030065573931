import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  CardActionArea,
} from "@mui/material";

interface IProps {
  image: string;
  imageAlt: string;
  title: string;
  text: string;
  subtitle: string;
}

export default function CardResources(props: IProps): JSX.Element {
  return (
    <Card
      sx={{
        height: 433,
        maxHeight: { xs: 433, md: 367 },
        maxWidth: { xs: 540, md: 540 },
      }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={props.image}
          alt={props.imageAlt}
        />
        <CardContent>
          <Typography variant="h5" component="div">
            {props.title}
          </Typography>
          <Typography
            gutterBottom
            variant="body1"
            component="div"
            color="text.secondary"
          >
            {props.subtitle}
          </Typography>
          <Typography variant="body2" color="Black">
            {props.text}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
