import React, { useState } from "react";
import Header from "./layouts/components/Header";
import PageFooter from "./layouts/components/PageFooter";
import Routes from "./routes";
import FaleConosco from "./Pages/FaleConosco";
import { BrowserRouter } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

function App() {
  const [open, setOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BrowserRouter>
      <Header handleOpen={handleClickOpen} />;
      <main>
        <Routes />
        <FaleConosco handleClose={handleClose} open={open} />
      </main>
      <PageFooter />
      <CookieConsent
        location="bottom"
        cookieName="cookieLewioComBr"
        expires={999}
        buttonText="Eu aceito"
        overlay
      >
        Esse website usa cookies para melhor experiência do usuário
      </CookieConsent>
    </BrowserRouter>
  );
}

export default App;
