import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

interface IProps {
  handleClose: () => void;
  open: boolean;
}

const PoliticaPrivacidade: React.FC<IProps> = (props: IProps) => {
  const { open, handleClose } = props;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Política de Privacidade</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div>
            <h2>POLÍTICA DE PRIVACIDADE</h2>
            <h3>SEÇÃO 1 - INFORMAÇÕES GERAIS</h3>
            <p>
              A presente Política de Privacidade contém informações sobre
              coleta, uso, armazenamento, tratamento e proteção dos dados
              pessoais dos usuários e visitantes do site www.lewio.com.br, com a
              finalidade de demonstrar absoluta transparência quanto ao assunto
              e esclarecer a todos interessados sobre os tipos de dados que são
              coletados, os motivos da coleta e a forma como os usuários podem
              gerenciar ou excluir as suas informações pessoais.
            </p>
            <p>
              Esta Política de Privacidade aplica-se a todos os usuários e
              visitantes do site www.lewio.com.br e integra os Termos e
              Condições Gerais de Uso do site www.lewio.com.br, devidamente
              inscrita no CNPJ sob o nº 00.991.054/0001-66, doravante nominada
              Lewio Prestação de Serviço de Informática Ltda.
            </p>
            <p>
              O presente documento foi elaborado em conformidade com a Lei Geral
              de Proteção de Dados Pessoais (Lei{" "}
              <a
                href="https://www.jusbrasil.com.br/legislacao/612902269/lei-13709-18"
                title="LEI Nº 13.709, DE 14 DE AGOSTO DE 2018."
              >
                13.709
              </a>
              /18), o{" "}
              <a
                href="https://www.jusbrasil.com.br/legislacao/117197216/lei-12965-14"
                title="LEI Nº 12.965, DE 23 ABRIL DE 2014."
              >
                Marco Civil da Internet
              </a>{" "}
              (Lei{" "}
              <a
                href="https://www.jusbrasil.com.br/legislacao/117197216/lei-12965-14"
                title="LEI Nº 12.965, DE 23 ABRIL DE 2014."
              >
                12.965
              </a>
              /14) (e o Regulamento da UE n. 2016/6790). Ainda, o documento
              poderá ser atualizado em decorrência de eventual atualização
              normativa, razão pela qual se convida o usuário a consultar
              periodicamente esta seção.
            </p>
            <h3>
              SEÇÃO 2 - COMO RECOLHEMOS OS DADOS PESSOAIS DO USUÁRIO E DO
              VISITANTE?
            </h3>
            <p>
              Os dados pessoais do usuário e visitante são recolhidos pela
              plataforma da seguinte forma:
            </p>
            <ul>
              <li>
                <p>
                  Quando um visitante acessa páginas do site www.lewio.com.br as
                  informações sobre interação e acesso são coletadas pela
                  empresa para garantir uma melhor experiência ao usuário e
                  visitante. Estes dados podem tratar sobre as palavras-chaves
                  utilizadas em uma busca, o compartilhamento de um documento
                  específico, comentários, visualizações de páginas, perfis, a
                  URL de onde o usuário e visitante provêm, o navegador que
                  utilizam e seus IPs de acesso, dentre outras que poderão ser
                  armazenadas e retidas.
                </p>
              </li>
            </ul>
            <h3>
              SEÇÃO 3 - QUAIS DADOS PESSOAIS RECOLHEMOS SOBRE O USUÁRIO E
              VISITANTE?
            </h3>
            <p>
              Os dados pessoais do usuário e visitante recolhidos são os
              seguintes:
            </p>
            <ul>
              <li>
                <p>
                  E-mail, nome completo e telefone, informados pelo usuário ao
                  solicitar que a Lewio entre em contato.
                </p>
              </li>
            </ul>
            <h3>
              SEÇÃO 3 - PARA QUE FINALIDADES UTILIZAMOS OS DADOS PESSOAIS DO
              USUÁRIO E VISITANTE?
            </h3>
            <p>
              Os dados pessoais do usuário e do visitante coletados e
              armazenados pela plataforma www.lewio.com.br tem por finalidade:
            </p>
            <ul>
              <li>
                <p>
                  Comercial: Para permitir que a empresa entre contato com o
                  visitante que deseja saber mais sobre os produtos fornecidos
                  pela empresa.
                </p>
              </li>
            </ul>
            <p>
              O tratamento de dados pessoais para finalidades não previstas
              nesta Política de Privacidade somente ocorrerá mediante
              comunicação prévia ao usuário, de modo que os direitos e
              obrigações aqui previstos permanecem aplicáveis.
            </p>
            <h3>
              SEÇÃO 4 - POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS?
            </h3>
            <p>
              Os dados pessoais do usuário e visitante são armazenados pela
              plataforma durante o período necessário para a prestação do
              serviço ou o cumprimento das finalidades previstas no presente
              documento, conforme o disposto no inciso{" "}
              <a
                href="https://www.jusbrasil.com.br/topicos/200399061/inciso-i-do-artigo-15-da-lei-n-13709-de-14-de-agosto-de-2018"
                title="Inciso I do Artigo 15 da Lei nº 13.709 de 14 de Agosto de 2018"
              >
                I
              </a>{" "}
              do artigo{" "}
              <a
                href="https://www.jusbrasil.com.br/topicos/200399064/artigo-15-da-lei-n-13709-de-14-de-agosto-de-2018"
                title="Artigo 15 da Lei nº 13.709 de 14 de Agosto de 2018"
              >
                15
              </a>{" "}
              da Lei{" "}
              <a
                href="https://www.jusbrasil.com.br/legislacao/612902269/lei-13709-18"
                title="LEI Nº 13.709, DE 14 DE AGOSTO DE 2018."
              >
                13.709
              </a>
              /18.
            </p>
            <p>
              Os dados podem ser removidos ou anonimizados a pedido do usuário,
              excetuando os casos em que a lei oferecer outro tratamento.
            </p>
            <p>
              Ainda, os dados pessoais dos usuários apenas podem ser conservados
              após o término de seu tratamento nas seguintes hipóteses previstas
              no artigo 16 da referida lei:{" "}
            </p>
            <p>
              I - cumprimento de obrigação legal ou regulatória pelo
              controlador;
            </p>
            <p>
              II - estudo por órgão de pesquisa, garantida, sempre que possível,
              a anonimização dos dados pessoais;
            </p>
            <p>
              III - uso exclusivo do controlador, vedado seu acesso por
              terceiro, e desde que anonimizados os dados.
            </p>
            <h3>SEÇÃO 5 - SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS</h3>
            <p>
              A plataforma se compromete a aplicar as medidas técnicas e
              organizativas aptas a proteger os dados pessoais de acessos não
              autorizados e de situações de destruição, perda, alteração,
              comunicação ou difusão de tais dados.{" "}
            </p>
            <p>
              A plataforma não se exime de responsabilidade por culpa exclusiva
              de terceiro, como em caso de ataque de hackers ou crackers, ou
              culpa exclusiva do usuário, como no caso em que ele mesmo
              transfere seus dados a terceiros. O site se compromete a comunicar
              o usuário em caso de alguma violação de segurança dos seus dados
              pessoais.
            </p>
            <p>
              Os dados pessoais armazenados são tratados com confidencialidade,
              dentro dos limites legais. No entanto, podemos divulgar suas
              informações pessoais caso sejamos obrigados pela lei para fazê-lo
              ou se você violar nossos Termos de Serviço.
            </p>
            <h3>SEÇÃO 6 - COMPARTILHAMENTO DOS DADOS</h3>
            <p>
              O compartilhamento de dados do usuário ocorre apenas internamente
              na empresa.
            </p>
            <h3>
              SEÇÃO 7 - OS DADOS PESSOAIS ARMAZENADOS SERÃO TRANSFERIDOS A
              TERCEIROS?
            </h3>
            <p>
              Os dados pessoais (não) podem ser compartilhados com terceiros.
            </p>
            <h3>SEÇÃO 8 – COOKIES OU DADOS DE NAVEGAÇÃO</h3>
            <p>
              Os cookies referem-se a arquivos de texto enviados pela plataforma
              ao computador do usuário e visitante e que nele ficam armazenados,
              com informações relacionadas à navegação no site. Tais informações
              são relacionadas aos dados de acesso como local e horário de
              acesso e são armazenadas pelo navegador do usuário e visitante
              para que o servidor da plataforma possa lê-las posteriormente a
              fim de personalizar os serviços da plataforma.
            </p>
            <p>
              O usuário e o visitante do site www.lewio.com.br manifesta
              conhecer e aceitar que pode ser utilizado um sistema de coleta de
              dados de navegação mediante à utilização de cookies.
            </p>
            <p>
              O cookie persistente permanece no disco rígido do usuário e
              visitante depois que o navegador é fechado e será usado pelo
              navegador em visitas subsequentes ao site. Os cookies persistentes
              podem ser removidos seguindo as instruções do seu navegador. Já o
              cookie de sessão é temporário e desaparece depois que o navegador
              é fechado. É possível redefinir seu navegador da web para recusar
              todos os cookies, porém alguns recursos da plataforma podem não
              funcionar corretamente se a capacidade de aceitar cookies estiver
              desabilitada.
            </p>
            <h3>SEÇÃO 9 - CONSENTIMENTO</h3>
            <p>
              Ao utilizar os serviços e fornecer as informações pessoais na
              plataforma, o usuário está consentindo com a presente Política de
              Privacidade.
            </p>
            <p>
              O usuário, ao cadastrar-se, manifesta conhecer e pode exercitar
              seus direitos de cancelar seu cadastro, acessar e atualizar seus
              dados pessoais e garante a veracidade das informações por ele
              disponibilizadas.
            </p>
            <p>
              O usuário tem direito de retirar o seu consentimento a qualquer
              tempo, para tanto deve entrar em contato através do e-mail
              suporte@lewio.com.br ou por correio enviado ao seguinte endereço
              de contato informado no site
            </p>
            <h3>SEÇÃO 10 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE</h3>
            <p>
              Reservamos o direito de modificar essa Política de Privacidade a
              qualquer momento, então, é recomendável que o usuário e visitante
              revise-a com frequência.
            </p>
            <p>
              As alterações e esclarecimentos vão surtir efeito imediatamente
              após sua publicação na plataforma. Quando realizadas alterações os
              usuários serão notificados. Ao utilizar o serviço ou fornecer
              informações pessoais após eventuais modificações, o usuário e
              visitante demonstra sua concordância com as novas normas.{" "}
            </p>
            <p>
              Diante da fusão ou venda da plataforma à outra empresa os dados
              dos usuários podem ser transferidas para os novos proprietários
              para que a permanência dos serviços oferecidos.
            </p>
            <h3>SEÇÃO 11 – JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS</h3>
            <p>
              Para a solução de controvérsias decorrentes do presente
              instrumento será aplicado integralmente o Direito brasileiro.
            </p>
            <p>
              Os eventuais litígios deverão ser apresentados no foro da comarca
              em que se encontra a sede da empresa.
            </p>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PoliticaPrivacidade;
