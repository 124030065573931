import React from "react";
import { Box, Typography, Button } from "@mui/material";

export default function downloads() {

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `Atualiza_Juridico.exe`;
    link.href = "./downloads/Atualiza_Juridico.exe";
    link.click();
  };

  return (
    <div>
      <Box ml={4} pt={13} sx={{ color: "#000" }}>
        <Typography sx={{ fontSize: "2.0rem", fontWeight: 100 }}>
          DOWNLOAD DA VERSÃO DESKTOP DO ABILJUR
        </Typography>
        <Typography sx={{ fontSize: "1.5rem", fontWeight: 100 }}>
          Baixe atualizações das últimas versões do Software
        </Typography>
        <Button onClick={onDownload} sx={{ m: "24px" }} size="large" variant="contained">
          Baixar Atualização
        </Button>
      </Box>
    </div>
  );
}
