import React, { useState } from "react";
import { Grid, Box, Container, Link } from "@mui/material";
import { LocalPhone, Email } from "@mui/icons-material";
import PoliticaPrivacidade from "../../Pages/PoliticaPrivacidade";

const PageFooter: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <footer>
      <Box
        px={{ xs: 3, sm: 4 }}
        py={{ xs: 4, sm: 5 }}
        mt={3}
        bgcolor="text.secondary"
        color="white"
      >
        <Container maxWidth="lg">
          <Grid container spacing={1}>
            <Grid item sm={6}>
              <Box
                sx={{ fontSize: "1.5rem", fontWeight: 600, color: "#F7BF20" }}
                mb={1}
              >
                Contato
              </Box>
              <Box ml={2} sx={{ fontWeight: 500 }}>
                Av. Mutinga, 1.249 - Conj. 2
              </Box>
              <Box ml={2} sx={{ fontWeight: 500 }}>
                Bairro Pirituba
              </Box>
              <Box ml={2} sx={{ fontWeight: 500 }}>
                São Paulo - SP
              </Box>
              <Box
                ml={2}
                mt={2}
                justifyContent="center"
                alignItems="center"
                justifyItems="center"
              >
                <LocalPhone
                  fontSize="small"
                  sx={{ verticalAlign: "text-bottom" }}
                />
                <Box component="span" ml={1} sx={{ fontWeight: 500 }}>
                  (11) 9.7334.9718
                </Box>
              </Box>

              <Box
                mt={1}
                ml={2}
                justifyContent="center"
                alignItems="center"
                justifyItems="center"
              >
                <Email fontSize="small" sx={{ verticalAlign: "text-bottom" }} />
                <Box component="span" ml={1} sx={{ fontWeight: 500 }}>
                  contato@lewio.com.br
                </Box>
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Link
                  sx={{ fontSize: "1.1rem", color: "#FFF", cursor: "pointer" }}
                  onClick={handleClickOpen}
                >
                  Política de Privacidade
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <PoliticaPrivacidade open={open} handleClose={handleClose} />
    </footer>
  );
};

export default PageFooter;
