import React from "react";

import Main from "./Pages/Main";
import Abiljur from "./Pages/AbilJur";
import Downloads from "./Pages/Downloads";
import Motiro from "./Pages/Motiro";
import { Routes, Route } from "react-router-dom";

export default function routes() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="abiljur" element={<Abiljur />} />
      <Route path="motiro" element={<Motiro />} />
      <Route path="downloads" element={<Downloads />} />
      <Route path="*" element={<Main />} />
    </Routes>
  );
}
