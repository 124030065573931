import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Box,
} from "@mui/material";

import { AlertColor } from "@mui/material/Alert";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import AlertMessage from "../components/AlertMessage";

interface IProps {
  handleClose: () => void;
  open: boolean;
}

interface IContact {
  name: string;
  email: string;
  phone: string;
  subject: string;
  comments: string;
}

interface IErrorRequired {
  name: boolean | null;
  emailOrPhone: boolean | null;
  subject?: boolean | null;
}

interface IAlert {
  message: string;
  severity: AlertColor;
  show: boolean;
}

function getEmptyValues(): IContact {
  return {
    name: "",
    email: "",
    phone: "",
    subject: "",
    comments: "",
  };
}

const baseUrl = "https://www.lewio.net:2003/sitelewioapi/api/contact";

const FaleConosco: React.FC<IProps> = (props: IProps) => {
  const { open, handleClose } = props;

  const [values, setValues] = useState<IContact>(getEmptyValues());

  const [errorRequired, setErrorRequired] = useState<IErrorRequired>({
    name: null,
    emailOrPhone: null,
    subject: null,
  });

  const [sending, setSending] = useState<boolean>(false);
  const [canSend, setCanSend] = useState<boolean>(false);
  const [alert, setAlert] = useState<IAlert>({
    message: "Erro geral",
    severity: "success",
    show: false,
  });

  function updateData(propertyName: string, v: any) {
    const value: string = v;

    setValues({ ...values, [propertyName]: v });

    if (propertyName === "phone") {
      setErrorRequired({
        ...errorRequired,
        emailOrPhone: value.trim() === "" && values.email === "",
      });
    } else if (propertyName === "email") {
      setErrorRequired({
        ...errorRequired,
        emailOrPhone: value.trim() === "" && values.phone === "",
      });
    } else if (errorRequired.hasOwnProperty(propertyName)) {
      setErrorRequired({
        ...errorRequired,
        [propertyName]: value.trim() === "",
      });
    }
  }

  function handleCloseAlert(): void {
    setAlert({ ...alert, show: false });

    if (alert.severity === "success") {
      setValues(getEmptyValues());
      handleClose();
    }
  }

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const propertyName = event.target.name;
    updateData(propertyName, event.target.value);
  };

  const handlePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.currentTarget.maxLength = 15;
    let value = e.currentTarget.value;

    if (value.length > 2 && value.length < 10) {
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{2})(\d)/, "($1) $2");
    } else if (value.length < 15) {
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{2})(\d{4})(\d)/, "($1) $2-$3");
    } else {
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{2})(\d{5})(\d)/, "($1) $2-$3");
    }
    updateData("phone", value);
  };

  const send = () => {
    setSending(true);

    axios
      .post(baseUrl, {
        name: values.name,
        email: values.email,
        phone: values.phone,
        subject: values.subject,
        comments: values.comments,
      })
      .then((response) => {
        console.log(response);
        console.log(response.data);

        setAlert({
          message: "Mensagem enviada com sucesso! Logo entraremos em contato",
          severity: "success",
          show: true,
        });

        setSending(false);
      })
      .catch((error) => {
        setAlert({
          message: error.response.data[0],
          severity: "error",
          show: true,
        });

        setSending(false);
      });
  };

  useEffect(() => {
    setCanSend(
      Object.values(errorRequired).filter((x) => x === true || x == null)
        .length === 0
    );
  }, [errorRequired]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Fale Conosco</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Informe os dados que em breve retornaremos para você.
        </DialogContentText>
        <Box component="form" noValidate autoComplete="off">
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Nome"
            type="text"
            value={values.name}
            fullWidth
            required
            variant="standard"
            error={errorRequired.name ?? false}
            helperText="Nome precisa ser preenchido."
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            name="email"
            label="e-mail"
            type="email"
            fullWidth
            error={errorRequired.emailOrPhone ?? false}
            variant="standard"
            helperText="E-mail ou Telefone precisa ser preenchido."
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            name="phone"
            label="Telefone"
            type="text"
            placeholder="(00) 9999-9999"
            fullWidth
            required
            helperText="E-mail ou Telefone precisa ser preenchido."
            error={errorRequired.emailOrPhone ?? false}
            variant="standard"
            value={values.phone}
            onChange={handlePhone}
          />

          <TextField
            margin="dense"
            name="subject"
            label="Assunto"
            type="text"
            fullWidth
            required
            helperText="Assunto precisa ser preenchido"
            error={errorRequired.subject ?? false}
            variant="standard"
            value={values.subject}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="comments"
            label="Comentários Adicionais"
            type="text"
            fullWidth
            multiline
            rows={3}
            variant="standard"
            onChange={handleChange}
          />
        </Box>

        {alert.show && (
          <AlertMessage
            message={alert.message}
            severity={alert.severity}
            onClose={handleCloseAlert}
          ></AlertMessage>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          loading={sending}
          disabled={!canSend}
          onClick={send}
        >
          Enviar
        </LoadingButton>
        <Button onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FaleConosco;
